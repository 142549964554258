<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false" @close="onResetForm('ruleForm')">
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download"
				>{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}健康{{ discriminationCode == 'E' ? '教育' : '计划' }}</span
			>
		</div>
		<el-form label-position="top" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<el-form-item label="标题" prop="title">
				<el-input v-model.trim="ruleForm.title" :disabled="isShowDetail" placeholder=" 请输入"></el-input>
			</el-form-item>
			<el-form-item label="卫生服务团队" prop="healthServiceGroupId">
				<el-select v-model="ruleForm.healthServiceGroupId" :disabled="isShowDetail">
					<el-option v-for="item in healthServiceGroupList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="学时" prop="creditHour">
				<el-input v-model.trim="ruleForm.creditHour" type="number" :disabled="isShowDetail" placeholder=" 请输入"></el-input>
			</el-form-item>
			<el-form-item label="上传文件类型" prop="educationTypeCode">
				<el-select v-model="ruleForm.educationTypeCode" :disabled="isShowDetail">
					<el-option label="文件" value="D"></el-option>
					<el-option label="视频" value="V"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="上传文件" prop="fileList" v-if="ruleForm.educationTypeCode == 'D'">
				<el-upload
					v-if="ruleForm.fileList.length === 0"
					action=""
					multiple
					:before-upload="beforeUpload"
					:http-request="uploadURL"
					:on-error="importError"
					:file-list="ruleForm.fileList"
					:show-file-list="false"
				>
					<el-button icon="el-icon-upload2">上传附件(PDF)</el-button>
				</el-upload>
				<div class="uploadFile-fileList" v-else>
					<p v-for="item in ruleForm.fileList" :key="item.uid" class="clearfix">
						<i class="el-icon-document"></i> {{ item.title }}
						<i class="el-icon-close float-r" @click="delfileList(item)" v-if="!isShowDetail"></i>
					</p>
				</div>
			</el-form-item>
			<el-form-item label="上传视频" prop="videos" v-if="ruleForm.educationTypeCode == 'V'">
				<UploadVideo ref="UploadVideo" v-model="ruleForm.videos" operator="zhangl" :maxCount="1" :disabled="isShowDetail"></UploadVideo>
			</el-form-item>
			<el-form-item class="func-btn">
				<el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button>
				<el-button v-else type="primary" @click="onSave('ruleForm')">保存</el-button>
				<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取消</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
import { client, headers, getFileNameUUID } from '@/utils/alioss.js';
export default {
	components: {
		Upload: () => import('@/components/Upload/index.vue'),
		UploadVideo: () => import('@/components/Upload/video.vue'),
	},
	data() {
		return {
			ruleForm: {
				title: '',
				healthServiceGroupId: null,
				creditHour: '',
				educationTypeCode: '',
				fileList: [],
				videos: [],
			},
			createdVisible: false,
			rules: {
				title: [{ required: true, message: '请输入', trigger: 'blur' }],
				healthServiceGroupId: [{ required: true, message: '请选择所属社区', trigger: 'change' }],
				creditHour: [{ required: true, message: '请输入', trigger: 'blur' }],
				educationTypeCode: [{ required: true, message: '请输入', trigger: 'blur' }],
				fileList: [{ required: true, message: '请上传图片', trigger: 'change' }],
				videos: [{ required: true, message: '请上传视频', trigger: 'change' }],
			},
			healthServiceGroupList: [],
			saveLoading: false,
			link: '',
			attachmentInfo: [],
			discriminationCode: '',
		};
	},
	props: ['isShowDetail'],
	watch: {},
	created() {},
	methods: {
		init(params) {
			this.createdVisible = true;
			this.link = params.link;
			this.discriminationCode = params.discriminationCode;
			this.getHealthServiceGroupList();
			this.getAttachment();
			if (params.row) {
				this.getDetails(params.link);
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate();
				});
				return;
			}
			// 新增
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		// 获取健康服务团队列表
		getHealthServiceGroupList() {
			this.$http
				.get(this.api['HealthServiceGroups#index'].href, { params: { size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.healthServiceGroupList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取详情
		async getDetails(link) {
			let res = await this.$http.get(link).then((res) => res);
			if (res.data && res.data.success) {
				let data = res.data.collection[0] || {};
				this.ruleForm = { ...data, fileList: [], videos: [] };
				this.ruleForm.healthServiceGroupId = data.healthServiceGroup?.id;
				if (!data.educationTypeCode || data.educationTypeCode == '') {
					this.ruleForm.fileList = [];
					this.ruleForm.videos = [];
				}
				if (data.educationTypeCode == 'D') {
					this.ruleForm.fileList =
						(data.attachmentDtoMap &&
							data.attachmentDtoMap['DOCUMENT'].map((item) => {
								return {
									url: item.link,
									uid: item.id,
									title: item.title,
									name: item.title,
								};
							})) ||
						[];
				} else {
					this.ruleForm.videos =
						(data.attachmentDtoMap &&
							data.attachmentDtoMap['VIDEO'].map((item) => {
								return {
									url: item.link,
									uid: item.id,
									title: item.title,
								};
							})) ||
						[];
				}
				this.$forceUpdate();
			}
		},
		// 保存
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					let fileList =
						this.ruleForm.fileList.map((item) => {
							return {
								link: item.url,
								subjectTypeCode: this.attachmentInfo.find((item) => item.code == 'DOCUMENT').subjectTypeCode,
								title: item.title,
								attachmentGroupId: this.attachmentInfo.find((item) => item.code == 'DOCUMENT').id,
								mimeType: item.title.split('.')[1],
							};
						}) || [];
					let videos =
						this.ruleForm.videos.map((item) => {
							return {
								link: item.url,
								subjectTypeCode: this.attachmentInfo.find((item) => item.code == 'VIDEO').subjectTypeCode,
								title: item.title,
								attachmentGroupId: this.attachmentInfo.find((item) => item.code == 'VIDEO').id,
								mimeType: item.title.split('.')[1],
							};
						}) || [];
					let params = {
						id: this.ruleForm.id || null,
						creditHour: this.ruleForm.creditHour,
						educationTypeCode: this.ruleForm.educationTypeCode,
						healthServiceGroupId: this.ruleForm.healthServiceGroupId,
						title: this.ruleForm.title,
						attachments: this.ruleForm.educationTypeCode == 'D' ? fileList : videos,
						discriminationCode: this.discriminationCode,
					};
					let queryApi = this.ruleForm.id ? this.$http.put(this.link, params) : this.$http.post(this.link, params);
					queryApi
						.then((res) => {
							if (res.data && res.data.success) {
								this.saveLoading = false;
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.onResetForm('ruleForm');
							}
						})
						.catch(() => {
							this.saveLoading = false;
						});
				} else {
					this.$nextTick(() => {
						let isError = document.getElementsByClassName('is-error');
						// var a = $(isError[0]).find("input") //this.$refs.cmd.$el.querySelector('input')
						// a.focus()  光标定位到所在input
						isError[0].scrollIntoView({
							// 滚动到指定节点
							// 值有start,center,end，nearest
							block: 'center',
							// 值有auto、instant,smooth，缓动动画
							behavior: 'smooth',
						});
					});
					return false;
				}
			});
		},
		// 重置
		onResetForm(formName) {
			this.createdVisible = false;
			this.ruleForm.medicalWorkerIdList = [{ name: '' }];
			this.$refs[formName].resetFields();
		},
		// 获取附件组
		getAttachment() {
			this.$http
				.get(this.api['AttachmentGroups#index'].href, { params: { subjectTypeCode: 'HOUSEHOLD_EDUCATION' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.attachmentInfo = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 自定义上传
		uploadURL(file) {
			let fileName = 'bernieri/zhangl/HEALTH_SERVICE_CONTRACT' + `/${getFileNameUUID()}` + '.pdf';
			client()
				.multipartUpload(fileName, file.file, {
					headers,
				})
				.then((res) => {
					const path = 'https://qdincrease-cd.oss-cn-chengdu.aliyuncs.com/' + res.name;
					if (res.res.status == 200) {
						this.$message({
							type: 'success',
							message: '导入成功',
						});
						this.$loading().close();
						this.ruleForm.fileList.push({
							title: file.file.name,
							url: path,
							uid: file.file.uid,
							name: file.file.name,
						});
					}
				});
		},
		beforeUpload(file) {
			let lastLen = file.name.lastIndexOf('.');
			let len = file.name.len;
			let type = file.name.substring(lastLen, len);
			if (type !== '.pdf') {
				this.$message({
					type: 'error',
					message: '请导入pdf文件',
				});
				return false;
			}
			this.$loading({
				lock: true,
				text: '导入中',
				background: 'rgba(0, 0, 0, 0.7)',
				spinner: 'el-icon-loading',
			});
		},
		importError(err, file, fileList) {
			let msg = err.toString().split('"msg":')[1];
			let showMsg = msg.length > 0 ? msg.split('"') : [];
			this.$message({
				type: 'error',
				message: showMsg[1] || '导入失败',
			});
			this.$loading().close();
		},
		delfileList(item) {
			this.ruleForm.fileList = this.ruleForm.fileList.filter((v) => v.uid !== item.uid);
		},
	},
};
</script>

<style lang="scss" scoped>
.showBorder {
	border: 1px solid red;
}
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}
	.el-upload {
		.el-button {
			margin-left: 0;
		}
	}
	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
}
/deep/ .el-button {
	margin-left: 16px;
}
.users-item {
	margin-bottom: 12px;
	display: grid;
	grid-template-columns: 136px 136px 136px 16px 16px;
	gap: 15px;
	i {
		line-height: 32px;
		color: #999;
		font-size: 16px;
		cursor: pointer;
	}
	i:hover {
		color: #1db9b1;
	}
	.icon-shanchu:hover {
		color: #f56c6c;
	}
}
.users-item:nth-last-child(1) {
	margin-bottom: 0;
}
.func-btn /deep/ .el-form-item__content {
	display: flex;
	justify-content: center;
}
.flex-tow {
	display: flex;
	align-items: center;
}
/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .w50 {
	width: calc(50% - 8px);
	display: inline-block;
}
.uploadFile-fileList {
	p {
		margin-top: 12px;
		color: #1db9b1;
		height: 14px;
		line-height: 14px;
		.el-icon-document {
			margin-right: 7px;
		}
		.el-icon-close {
			display: none;
		}
		&:hover {
			.el-icon-close {
				display: block;
				cursor: pointer;
			}
		}
	}
}
</style>